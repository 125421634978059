.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgrey);

}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.socials{
    display: flex;
    gap: 4rem;
}
.socials>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.blur1{
    bottom: 0rem;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;

}
.blur2{
    bottom: 0rem;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(230, 100, 14);

}